<template>
  <div v-loading="allLoading" class="order-manage">
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      noEmptyText="el.common.noData"
    >
      <template slot="otherHandle" slot-scope="scope">
        <el-button
          v-btn="'btn-order-detail'"
          type="text"
          size="small"
          @click="handleGoDetail(scope.row)"
          >{{ $t("el.courseDetails.details") }}</el-button
        >
      </template>
      <template slot="formBtn">
        <el-button
          v-btn="'btn-order-list-export'"
          size="small"
          class="teachManage-tool-btnicon"
          type="primary"
          @click="handleExport()"
          >{{ $t("el.common.export") }}</el-button
        >
      </template>
      <template slot="formBtn1">
        <span
          style="
            color: #807f7f;
            position: absolute;
            right: 50px;
            top: -40px;
            font-size: 14px;
          "
          >{{ $t("el.order.autoreject") }}</span
        >
      </template>
    </xm-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  refundListApi,
  //  refundSubjectApi
} from "@/api/manage/order";
import { handparams } from "@/utils";

export default {
  computed: {
    ...mapGetters({}),
    ...mapState({
      // 当前学校租户下的年级
      gradeOptions(state) {
        return state.dict.TENANT_GRADE.map((item) => ({
          label: item.dictionaryText,
          value: item.dictionaryKey,
        }));
      },
      tenantId: (state) => state.schoolCode,
    }),

    // 筛选项字段
    formItems() {
      return [
        // 退款单号
        {
          itemType: "input",
          prop: "refundNo",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.refundNo"),
          clearable: true,
        }, // 申请时间
        {
          itemType: "daterange",
          prop: "applyTime",
          size: "small",
          prop1: "applyTimeStart",
          prop2: "applyTimeEnd",
          "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.order.applyTime"),
          clearable: true,
        }, // 订单编号
        {
          itemType: "input",
          prop: "orderNo",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.orderNo"),
          clearable: true,
        },
        // 课程名称
        {
          itemType: "input",
          prop: "courseName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.CourseName"),
          clearable: true,
        }, // 收款商户
        {
          itemType: "input",
          prop: "merchantName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.shoukuanshanghu"),
          clearable: true,
        },
        // 报名学生
        {
          itemType: "input",
          prop: "studentName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.registeredStudents"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "gradeName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.grade"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "className",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.class"),
          clearable: true,
        },
        // {
        //   itemType: "select",
        //   prop: "gradeId",
        //   size: "small",
        //   placeholder: this.$t("el.common.pleaseSelect"),
        //   label: this.$t("el.order.grade"),
        //   clearable: true,
        //   options: this.gradeOptions,
        // },
        // // 班级
        // {
        //   itemType: "select",
        //   prop: "classId",
        //   size: "small",
        //   placeholder: this.$t("el.common.pleaseSelect"),
        //   label: this.$t("el.order.class"),
        //   clearable: true,
        //   options: this.classOptions,
        // },
        // 用户手机号
        {
          itemType: "input",
          prop: "userPhone",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.userPhoneNumber"),
          clearable: true,
          itemStyle: "width:150px;",
        }, // 审核状态
        {
          itemType: "select",
          prop: "verifyStatus",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.schoolResourceManage.auditStatus"),
          clearable: true,
          options: this.$store.state.dict.VERITY_STATUS.map((el) => {
            return {
              label: el.dictionaryText,
              value: el.dictionaryKey,
            };
          }),
        }, // 退款状态
        {
          itemType: "select",
          prop: "refundStatus",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.order.refundStatus"),
          clearable: true,
          options: this.$store.state.dict.REFUND_STATUS.map((el) => {
            return {
              label: el.dictionaryText,
              value: el.dictionaryKey,
            };
          }).filter((i) => i.value < 5),
        },
      ];
    },
    // 表格项
    columns() {
      return [
        {
          label: this.$t("el.order.applyTime"),
          prop: "applyTime",
          formatter: (row, column, value) => {
            return (
              this.$utils.getFormatterDate(new Date(value)) +
              " " +
              this.$utils.getFormatterTime(new Date(value))
            );
          },
        },
        {
          label: this.$t("el.order.refundNo"),
          prop: "refundNo",
        },

        {
          label: this.$t("el.order.orderNo"),
          prop: "orderNo",
        },
        {
          label: this.$t("el.order.courseName"),
          prop: "courseName",
        },
        {
          label: this.$t("el.order.shoukuanshanghu"),
          prop: "merchantName",
        },
        {
          label: this.$t("el.order.registeredStudents"),
          prop: "studentName",
        },
        {
          label: this.$t("el.order.grade"),
          prop: "gradeName",
        },
        {
          label: this.$t("el.order.class"),
          prop: "className",
        },
        {
          label: this.$t("el.order.userPhoneNumber"),
          prop: "userPhone",
        },
        {
          label: this.$t("el.order.dingdanjine") + this.$t("el.order.yuan"),
          prop: "orderAmount",
        },
        {
          label:
            this.$t("el.order.shenqingtuikuanjine") + this.$t("el.order.yuan"),
          prop: "amount",
        },
        {
          label:
            this.$t("el.order.shijituikuanjine") + this.$t("el.order.yuan"),
          prop: "realAmount",
        },
        {
          label: this.$t("el.schoolResourceManage.auditStatus"),
          prop: "verifyStatus",
          formatter: (row, column, value) => {
            return (
              this.$store.state.dict.VERITY_STATUS.map(
                (el) => el.dictionaryText
              )[value - 1] || "-"
            );
          },
        },
        {
          label: this.$t("el.order.refundStatus"),
          prop: "refundStatus",
          formatter: (row, column, value) => {
            return this.$store.state.dict.REFUND_STATUS.map(
              (el) => el.dictionaryText
            )[value - 1];
          },
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
        },
      ];
    },
  },
  data() {
    return {
      allLoading: false,
      classOptions: [],
    };
  },
  created() {
    // this.handleGetYear();
    this.handleGetSubject();
  },
  methods: {
    // 获取列表数据
    fatchMethod(params) {
      this.doTimeParams(params);

      return refundListApi(handparams(params));
    },
    doTimeParams(params) {
      delete params.applyTime;
      if (params.applyTimeStart) {
        params.applyTimeStart = +new Date(params.applyTimeStart + " 00:00:00");
      }
      if (params.applyTimeEnd) {
        params.applyTimeEnd = +new Date(params.applyTimeEnd + " 23:59:59");
      }
    },
    // 获取列表内全部科目
    handleGetSubject() {
      // refundSubjectApi(this.tenantId).then((res) => {
      //   this.subjectOptions = [
      //     ...(res?.data || []).map(({ id, name }) => ({
      //       value: id,
      //       label: name,
      //     })),
      //   ];
      // });
    },

    // 查看详情
    handleGoDetail(row) {
      const query = { refundId: row.refundId };
      this.$router.push({
        name: "refundDetail",
        query,
      });
    },
    /**
     * 导出
     */
    async handleExport() {
      let queryParams = this.$refs.xmTable.getQueryParams(true);
      this.doTimeParams(queryParams);
      this.allLoading = true;
      if (this.$refs.xmTable.getNowDataList().length > 0) {
        try {
          await this.$utils.exportDownCommon(
            "get",
            handparams(queryParams),
            "/school/refund/export",
            `学校退款订单_${new Date().getTime()}.xlsx`
          );
        } catch (error) {
          //
        }
      } else {
        this.$message.warning(this.$t("el.common.noData"));
      }

      this.allLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.order-manage {
  padding: 30px 50px;
  background-color: #fff;
  border-radius: 10px;
  .syncedTip {
    text-align: right;
    font-size: 14px;
    color: #807f7f;
    margin-bottom: 12px;
  }
  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
}
</style>
