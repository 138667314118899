<template>
  <el-dialog
    :title="isSubmit ? '提交结算凭证' : '结算凭证'"
    custom-class="settle-submit-dialog"
    :visible="submitDialogVisible"
    @close="$emit('change', false)"
    width="660px"
  >
    <p class="settle-submit-dialog-tip">
      如有因收款平台费率或者结算双方账款分成等原因导致的实际打款金额和需结算金额不一致的，请说明原因并提交补充证明（如款项计算明细或者协商一致的证明）以供收款方确认。
    </p>
    <div
      class="settle-submit-dialog-content"
      :class="{ 'settle-submit-dialog-content-isedit': isEdit }"
    >
      <p class="settle-submit-dialog-content-item">
        <span class="settle-submit-dialog-content-item-title">结算单号：</span
        ><span>{{ settlementItemDetail.settlementNo }}</span>
      </p>
      <p class="settle-submit-dialog-content-item">
        <span class="settle-submit-dialog-content-item-title">结算时段：</span
        ><span>
          {{ getFormatDate(settlementItemDetail.settlementStartTime) }}-{{
            getFormatDate(settlementItemDetail.settlementEndTime)
          }}
        </span>
      </p>
      <p class="settle-submit-dialog-content-item">
        <span class="settle-submit-dialog-content-item-title">结算对象：</span
        ><span>{{ settlementItemDetail.tenantName }}</span>
      </p>
      <p class="settle-submit-dialog-content-item">
        <span class="settle-submit-dialog-content-item-title">需结算金额：</span
        ><span>{{ settlementItemDetail.settlementAmount }}</span
        ><span> 元</span>
      </p>
      <template v-if="!isEdit">
        <p
          class="settle-submit-dialog-content-item"
          v-for="(item, index) in viewData"
          :key="index"
          :style="item.itemStyle"
        >
          <span class="settle-submit-dialog-content-item-title">{{
            item.label
          }}</span>
          <template v-if="item.itemType === 'upload'">
            <upload-file
              class="settle-submit-dialog-content-item-value"
              :value="item.value"
              :isEdit="false"
              v-bind="item"
              :style="item.itemStyle || 'width: 200px;'"
              @fileChange="item.fileChange"
            />
          </template>
          <span v-else
            ><span>{{ item.value }}</span
            ><span v-if="item.suffix">{{ item.suffix }}</span>
          </span>
        </p>
      </template>
    </div>
    <xm-form
      v-if="isEdit"
      ref="form"
      :form-items="formItems"
      :form-options="newFormOptions"
      :form-data="queryParams"
    ></xm-form>
    <span slot="footer">
      <!-- 若机构已确认此区域结算状态为已完成，修改按钮隐藏 -->
      <el-button
        v-btn="'btn-settle-voucher-edit'"
        v-if="
          !isEdit &&
          !isSubmit &&
          settlementItemDetail.settlementItemStatus !== 3
        "
        type="primary"
        size="medium"
        class="editdialog-footer-btn"
        @click="isEdit = true"
        :loading="apiLoading"
        >修改</el-button
      >
      <el-button
        v-if="isEdit"
        type="primary"
        size="medium"
        class="editdialog-footer-btn"
        @click="centerSubmit"
        :loading="apiLoading"
        >确认</el-button
      >
      <el-button
        size="medium"
        class="editdialog-footer-btn"
        @click="$emit('change', false)"
        >{{ $t("el.common.cancel") }}</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import {
  handparams,
  getFormatDate,
  extractFileNameFromURL,
  getFormatTime,
} from "@/utils";
import {
  settleVoucherDetailApi,
  settlementSubmitVoucherApi,
} from "@/api/manage/order";
import UploadFile from "@/components/UploadFile/index.vue";
export default {
  model: {
    prop: "submitDialogVisible",
    event: "change",
  },
  props: {
    submitDialogVisible: Boolean,
    isSubmit: Boolean,
    settlementItemDetail: Object,
  },
  components: { UploadFile },
  watch: {
    isSubmit: {
      handler(val) {
        if (!val) {
          this.settleVoucherDetail(this.settlementItemDetail.settlementItemId);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      getFormatDate: getFormatDate,
      getFormatTime: getFormatTime,
      isEdit: false,
      queryParams: {
        settlementVoucher: "", // 上传凭证 对象
        supplementVoucher: "", // 补充说明 对象
        settlementRealAmount: "",
        settlementRemark: "",
        settlementVoucherObject: "",
        settlementVoucherObjectBack: "", // 用于接收上传后的内容
        settlementVoucherDisplay: "",
        settlementVoucherDisplayBack: "",
        supplementVoucherObject: "",
        supplementVoucherObjectBack: "", // 用于接收上传后的内容
        supplementVoucherDisplay: "",
        supplementVoucherDisplayBack: "",
      },
      viewData: [], // 用来展示数据
      apiLoading: false, // 接口防抖
    };
  },
  computed: {
    isShowSupplementVoucher() {
      // 是否展示补充说明,若实际打款金额和需结算金额一致时，不显示此项。
      if (this.queryParams.settlementRealAmount !== undefined) {
        return (
          parseFloat(this.queryParams.settlementRealAmount) !==
          parseFloat(this.settlementItemDetail.settlementAmount)
        );
      }
      return false;
    },
    rules() {
      const _this = this;
      return {
        settlementRealAmount: [
          {
            required: _this.isEdit,
            message: "请输入实际打款金额",
            trigger: "blur",
          },
        ],
        settlementRemark: [
          { required: _this.isEdit, message: "请输入结算说明" },
        ],
        settlementVoucher: [
          {
            required: _this.isEdit,
            validator(rule, value, callback) {
              if (_this.queryParams.settlementVoucherObjectBack) {
                callback();
              } else {
                callback(new Error("请上传打款凭证"));
              }
            },
          },
        ],
        supplementVoucher: [
          {
            required: _this.isEdit && this.isShowSupplementVoucher,
            validator(rule, value, callback) {
              if (_this.isShowSupplementVoucher) {
                if (_this.queryParams.supplementVoucherObjectBack) {
                  callback();
                } else {
                  callback(new Error("请上传补充说明"));
                }
              } else {
                callback();
              }
            },
          },
        ],
      };
    },
    newFormOptions() {
      return {
        "label-width": "120px",
        rules: this.rules,
        "validate-on-rule-change": false,
      };
    },
    formItems() {
      return [
        {
          required: true,
          itemType: this.isEdit ? "inputMoney" : "text",
          isSubmit: this.isSubmit,
          suffix: "元",
          precision: 2,
          max: parseFloat(this.settlementItemDetail.settlementAmount),
          prop: "settlementRealAmount",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: "实际打款金额",
          clearable: true,
          itemStyle: {
            width: "160px",
          },
        },
        {
          itemType: this.isEdit ? "input" : "text",
          type: "textarea",
          prop: "settlementRemark",
          size: "small",
          placeholder: "请输入结算说明",
          label: "结算说明",
          maxlength: 200,
          "show-word-limit": true,
          autosize: {
            minRows: 4,
          },
          clearable: true,
          itemStyle: {
            width: "60%",
          },
        },
        {
          itemType: "upload",
          type: "image",
          isEdit: this.isEdit,
          limit: 1,
          prop: "settlementVoucher",
          label: "打款凭证",
          itemStyle: {
            width: "100%",
          },
          fileChange: (file) => {
            this.queryParams.settlementVoucherObjectBack = file.length
              ? file[0].key || extractFileNameFromURL(file[0].url)
              : "";
            this.queryParams.settlementVoucherDisplayBack = file.length
              ? file[0].name
              : "";
            this.$refs["form"].$refs["form"].validateField("settlementVoucher");
          },
        },
        {
          itemType: "upload",
          type: "image",
          isEdit: this.isEdit,
          limit: 1,
          prop: "supplementVoucher",
          label: "补充说明",
          itemStyle: {
            width: "100%",
          },
          hidden: !this.isShowSupplementVoucher,
          fileChange: (file) => {
            this.queryParams.supplementVoucherObjectBack = file.length
              ? file[0].key || extractFileNameFromURL(file[0].url)
              : "";
            this.queryParams.supplementVoucherDisplayBack = file.length
              ? file[0].name
              : "";
            this.$refs["form"].$refs["form"].validateField("supplementVoucher");
          },
        },
        {
          itemType: "text",
          prop: "crtBy",
          size: "small",
          label: "提交人",
          itemStyle: {
            width: "60%",
          },
          hidden: this.isEdit,
        },
        {
          itemType: "text",
          type: "time",
          prop: "crtTime",
          size: "small",
          label: "提交时间",
          itemStyle: {
            width: "60%",
          },
          hidden: this.isEdit,
        },
      ];
    },
  },
  mounted() {
    this.isEdit = this.isSubmit;
  },
  methods: {
    centerSubmit() {
      // 确认按钮
      this.$refs["form"].$refs["form"].validate((valid) => {
        if (valid) {
          this.apiLoading = true;
          const data = handparams(this.queryParams);
          data.settlementItemId = this.settlementItemDetail.settlementItemId;
          data.settlementVoucherObject = data.settlementVoucherObjectBack;
          data.settlementVoucherDisplay = data.settlementVoucherDisplayBack;
          data.supplementVoucherObject = data.supplementVoucherObjectBack;
          data.supplementVoucherDisplay = data.supplementVoucherDisplayBack;
          delete data.settlementVoucher;
          delete data.supplementVoucher;
          delete data.settlementVoucherObjectBack;
          delete data.supplementVoucherObjectBack;
          delete data.settlementVoucherDisplayBack;
          delete data.supplementVoucherDisplayBack;
          if (!this.isShowSupplementVoucher) {
            delete data.supplementVoucherObject;
            delete data.supplementVoucherDisplay;
          }
          settlementSubmitVoucherApi(data)
            .then(() => {
              this.$message.success(this.isSubmit ? "提交成功" : "修改成功");
              this.$emit("change", false);
              this.$emit("query");
            })
            .finally(() => {
              this.apiLoading = false;
            });
        }
      });
    },
    settleVoucherDetail(settlementItemId) {
      settleVoucherDetailApi({ settlementItemId }).then((res) => {
        Object.assign(this.queryParams, res.data);
        this.$set(this.queryParams, "settlementVoucher", {
          name: this.queryParams.settlementVoucherDisplay,
          url: this.queryParams.settlementVoucherObject,
        });
        this.$set(this.queryParams, "supplementVoucher", {
          name: this.queryParams.supplementVoucherDisplay,
          url: this.queryParams.supplementVoucherObject,
        });
        this.$set(
          this.queryParams,
          "settlementVoucherObjectBack",
          this.queryParams.settlementVoucherObject
        );
        this.$set(
          this.queryParams,
          "settlementVoucherDisplayBack",
          this.queryParams.settlementVoucherDisplay
        );
        this.$set(
          this.queryParams,
          "supplementVoucherObjectBack",
          this.queryParams.supplementVoucherObject
        );
        this.$set(
          this.queryParams,
          "supplementVoucherDisplayBack",
          this.queryParams.supplementVoucherDisplay
        );
        const viewData = [
          {
            label: "实际打款金额：",
            value: this.queryParams.settlementRealAmount,
            itemStyle: {
              width: "100%",
            },
            suffix: "元",
          },
          {
            label: "结算说明：",
            value: this.queryParams.settlementRemark,
            itemStyle: {
              width: "100%",
            },
          },
          {
            label: "打款凭证：",
            value: this.queryParams.settlementVoucher,
            itemType: "upload",
            fileChange: () => {},
            itemStyle: {
              width: "100%",
            },
          },
          {
            label: "提交人：",
            value: this.queryParams.crtBy,
          },
          {
            label: "提交时间：",
            value: this.getFormatTime(this.queryParams.crtTime),
          },
        ];
        const supplementVoucherQuery = {
          label: "补充说明：",
          value: this.queryParams.supplementVoucher,
          itemType: "upload",
          fileChange: () => {},
          itemStyle: {
            width: "100%",
          },
        };
        if (this.isShowSupplementVoucher) {
          viewData.splice(3, 0, supplementVoucherQuery);
        }
        this.viewData = viewData;
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .settle-submit-dialog {
  .el-dialog__body {
    padding-top: 10px;
  }
}
.settle-submit-dialog {
  &-tip {
    color: @themeOrange;
    background-color: @themeBgOrange;
    text-align: left;
    padding: 8px;
    font-size: 12px;
    line-height: 18px;
  }
  &-content {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    &-item {
      width: 50%;
      text-align: left;
      line-height: 1.5rem;
      display: flex;
      // align-items: center;
      margin: 8px 0;
      /deep/ .file-list {
        .file-list-item {
          margin-top: 0px;
        }
      }
      &-title {
        margin-left: 9px;
        float: left;
        min-width: 70px;
      }
      &-value {
        flex: 1;
        overflow: hidden;
      }
    }
  }
  .settle-submit-dialog-content-isedit {
    .settle-submit-dialog-content-item {
      margin: 11px 0;
      &:last-child {
        margin-bottom: 22px;
      }
    }
  }
  .el-form {
    /deep/ .el-form-item {
      // margin-bottom: 8px;
      .el-form-item__content {
        display: flex;
        text-align: left;
      }
      .el-textarea {
        textarea {
          padding-bottom: 15px;
        }
        .el-input__count {
          background: transparent;
          line-height: 12px;
        }
      }
    }
  }
}
</style>
