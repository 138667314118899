<template>
  <el-dialog
    title="查看订单"
    custom-class="settle-view-dialog"
    :visible="viewDialogVisible"
    @close="$emit('change', false)"
    width="1024px"
  >
    <div class="settle-view-dialog-content">
      <p class="settle-view-dialog-content-item">
        <span>结算单号：</span
        ><span>{{ settlementItemDetail.settlementNo }}</span>
      </p>
      <p class="settle-view-dialog-content-item">
        <span>结算时段：</span
        ><span>
          {{ getFormatDate(settlementItemDetail.settlementStartTime) }}-{{
            getFormatDate(settlementItemDetail.settlementEndTime)
          }}
        </span>
      </p>
      <p class="settle-view-dialog-content-item">
        <span>结算对象：</span
        ><span>{{ settlementItemDetail.tenantName }}</span>
      </p>
    </div>
    <xm-table
      ref="xmTable"
      :hideForm="true"
      :fatch-method="fatchMethod"
      :columns="columns"
      :autoLoad="true"
      noEmptyText="el.common.noData"
      :tableOptions="{
        customCellStyle: true,
        headerStyle: 'bskHeader',
        cellStyle: () => 'bskCell bskCell-2',
      }"
    >
      <template slot="otherHandle" slot-scope="scope">
        <el-button
          v-if="!scope.row.hasSettle"
          type="text"
          size="small"
          @click="handleSubmit(scope.row)"
          >{{ $t("el.order.settlementSubmit") }}</el-button
        >
        <el-button
          v-else
          size="small"
          type="text"
          @click="handleRecord(scope.row)"
          >{{ $t("el.order.settlementRecord") }}</el-button
        >
        <el-button size="small" type="text" @click="handleView(scope.row)">{{
          $t("el.order.view")
        }}</el-button>
      </template>
    </xm-table>
  </el-dialog>
</template>
<script>
import { settleDetailApi } from "@/api/manage/order";
import { getFormatDate } from "@/utils";

export default {
  model: {
    prop: "viewDialogVisible",
    event: "change",
  },
  props: {
    viewDialogVisible: Boolean,
    settlementItemDetail: Object,
  },
  data() {
    return {
      getFormatDate: getFormatDate,
    };
  },
  computed: {
    columns() {
      return [
        {
          label: "订单编号",
          prop: "orderNo",
          width: "200px",
        },
        {
          label: "子单编号",
          prop: "orderItemNo",
          width: "220px",
        },
        {
          label: "创建时间",
          prop: "crtTime",
          width: "160px",
          showType: "time",
        },
        {
          label: "课程名称",
          prop: "courseName",
          width: "160px",
        },
        {
          label: "订单金额(元)",
          prop: "orderAmount",
        },
        {
          label: "退款金额(元)",
          prop: "refundAmount",
        },
        {
          label: "结算金额(元)",
          prop: "settlementAmount",
        },
      ];
    },
  },
  methods: {
    fatchMethod(params) {
      params.settlementItemId = this.settlementItemDetail.settlementItemId;
      return settleDetailApi(params)
        .then((result) => {
          return result;
        })
        .catch(() => {
          return null;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.settle-view-dialog {
  &-content {
    display: flex;
    justify-content: space-between;
    &-item {
      width: 33.3%;
    }
  }
}
</style>
