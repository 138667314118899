<template>
  <div class="settlement-container">
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :autoLoad="true"
      noEmptyText="el.common.noData"
      :tableOptions="{
        customCellStyle: true,
        headerStyle: 'bskHeader',
        cellStyle: () => 'bskCell bskCell-2',
      }"
      :formOptions="{
        newDefaultFormBtn: true,
      }"
    >
      <template slot="otherHandle" slot-scope="scope">
        <el-button
          v-btn="'btn-settle-voucher-edit'"
          v-if="scope.row.settlementVoucherStatus === 1"
          type="text"
          size="small"
          @click="handleSubmit(scope.row)"
          >{{ $t("el.order.settlementSubmit") }}</el-button
        >
        <el-button
          v-btn="'btn-settle-voucher-view'"
          v-else
          size="small"
          type="text"
          @click="handleRecord(scope.row)"
          >{{ $t("el.order.settlementRecord") }}</el-button
        >
        <el-button
          v-btn="'btn-settle-view'"
          size="small"
          type="text"
          @click="handleView(scope.row)"
          >{{ $t("el.order.view") }}</el-button
        >
      </template>
    </xm-table>
    <view-dialog
      v-if="viewDialogVisible"
      v-model="viewDialogVisible"
      :settlementItemDetail="settlementItemDetail"
    />
    <submit-dialog
      v-if="submitDialogVisible"
      v-model="submitDialogVisible"
      :isSubmit="isSubmit"
      :settlementItemDetail="settlementItemDetail"
      @query="query"
    />
  </div>
</template>
<script>
import ViewDialog from "./dialog/ViewDialog.vue";
import SubmitDialog from "./dialog/SubmitDialog.vue";
import { settleListApi, checkSubmitVoucherApi } from "@/api/manage/order";
import { mapGetters } from "vuex";
import { handparams, getFormatDate } from "@/utils";

export default {
  components: { ViewDialog, SubmitDialog },
  data() {
    return {
      viewDialogVisible: false,
      submitDialogVisible: false,
      isSubmit: false, // 提交结算凭证/查看结算凭证
      settlementItemDetail: null, // 结算单明细
    };
  },
  computed: {
    ...mapGetters([
      "tenantTypeListForSchool",
      "submitStatusList",
      "settleStatusListForSchool",
    ]),
    formItems() {
      return [
        {
          itemType: "input",
          prop: "settlementNo",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.settlementNumber"),
          clearable: true,
        },
        {
          itemType: "daterange",
          prop: "crtTime",
          size: "small",
          prop1: "settlementStartTime",
          prop2: "settlementEndTime",
          "popper-append-to-body": false,
          format: "yyyy-MM-dd",
          "value-format": "timestamp",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.order.settlementTimeInterval"),
          clearable: true,
        },
        {
          itemType: "select",
          prop: "tenantType",
          size: "small",
          options: this.tenantTypeListForSchool,
          "popper-append-to-body": false,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.order.settlementObjType"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "tenantName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.settlementObj"),
          clearable: true,
        },
        {
          itemType: "select",
          prop: "settlementVoucherStatus",
          size: "small",
          options: this.submitStatusList,
          "popper-append-to-body": false,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: "结算凭证提交",
          clearable: true,
        },
        {
          itemType: "select",
          prop: "settlementItemStatus",
          size: "small",
          options: this.settleStatusListForSchool,
          "popper-append-to-body": false,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.order.settlementStatus"),
          clearable: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("el.order.settlementNumber"),
          prop: "settlementNo",
          width: "200px",
        },
        {
          label: this.$t("el.order.settlementTimeInterval"),
          prop: "time",
          formatter: (row) =>
            `${getFormatDate(row.settlementStartTime)}-${getFormatDate(
              row.settlementEndTime
            )}`,
          width: "180px",
        },
        {
          label: this.$t("el.order.settlementObjType"),
          prop: "tenantType",
          dictName: "TENANT_TYPE",
        },
        {
          label: this.$t("el.order.settlementObjName"),
          prop: "tenantName",
          width: "160px",
        },
        {
          label: this.$t("el.order.orderTotalMoney") + this.$t("el.order.yuan"),
          prop: "orderTotalAmount",
        },
        {
          label:
            this.$t("el.order.orderTotalRefund") + this.$t("el.order.yuan"),
          prop: "refundTotalAmount",
        },
        {
          label:
            this.$t("el.order.settlementMoneyNeed") + this.$t("el.order.yuan"),
          prop: "settlementAmount",
        },
        {
          label:
            this.$t("el.order.settlementMoneyUsed") + this.$t("el.order.yuan"),
          prop: "settlementRealAmount",
          tip: "存在因收款平台手续费和双方分成导致的实际打款金额和需结算金额不一致的情况，请双方协商一致后提交结算或者确认结算。",
          width: "100px",
        },
        {
          label: "结算凭证提交",
          prop: "settlementVoucherStatus",
          dictName: "SUBMIT_STATUS",
        },
        {
          label: this.$t("el.order.settlementStatus"),
          prop: "settlementItemStatus",
          dictName: "SETTLE_STATUS",
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
          width: "140px",
          fixed: "right",
        },
      ];
    },
  },
  methods: {
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },
    fatchMethod(params) {
      const data = handparams(params);
      delete data.crtTime;
      return settleListApi(data)
        .then((result) => {
          return result;
        })
        .catch(() => {
          return null;
        });
    },
    // 提交结算
    handleSubmit(row) {
      checkSubmitVoucherApi({ settlementItemId: row.settlementItemId }).then(
        (res) => {
          if (res.data) {
            this.submitDialogVisible = true;
            this.isSubmit = true;
            this.settlementItemDetail = row;
          } else {
            this.$message.error("结算凭证已提交，请刷新页面");
          }
        }
      );
    },
    // 结算凭证
    handleRecord(row) {
      this.submitDialogVisible = true;
      this.isSubmit = false;
      this.settlementItemDetail = row;
    },
    // 查看订单
    handleView(row) {
      this.viewDialogVisible = true;
      this.settlementItemDetail = row;
    },
  },
};
</script>
<style lang="less" scoped>
.settlement-container {
  padding: 30px 50px;
  background-color: #fff;
  border: 10px;
}
</style>
