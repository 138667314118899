var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settlement-container"},[_c('xm-table',{ref:"xmTable",attrs:{"fatch-method":_vm.fatchMethod,"form-items":_vm.formItems,"columns":_vm.columns,"autoLoad":true,"noEmptyText":"el.common.noData","tableOptions":{
      customCellStyle: true,
      headerStyle: 'bskHeader',
      cellStyle: () => 'bskCell bskCell-2',
    },"formOptions":{
      newDefaultFormBtn: true,
    }},scopedSlots:_vm._u([{key:"otherHandle",fn:function(scope){return [(scope.row.settlementVoucherStatus === 1)?_c('el-button',{directives:[{name:"btn",rawName:"v-btn",value:('btn-settle-voucher-edit'),expression:"'btn-settle-voucher-edit'"}],attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleSubmit(scope.row)}}},[_vm._v(_vm._s(_vm.$t("el.order.settlementSubmit")))]):_c('el-button',{directives:[{name:"btn",rawName:"v-btn",value:('btn-settle-voucher-view'),expression:"'btn-settle-voucher-view'"}],attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.handleRecord(scope.row)}}},[_vm._v(_vm._s(_vm.$t("el.order.settlementRecord")))]),_c('el-button',{directives:[{name:"btn",rawName:"v-btn",value:('btn-settle-view'),expression:"'btn-settle-view'"}],attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.handleView(scope.row)}}},[_vm._v(_vm._s(_vm.$t("el.order.view")))])]}}])}),(_vm.viewDialogVisible)?_c('view-dialog',{attrs:{"settlementItemDetail":_vm.settlementItemDetail},model:{value:(_vm.viewDialogVisible),callback:function ($$v) {_vm.viewDialogVisible=$$v},expression:"viewDialogVisible"}}):_vm._e(),(_vm.submitDialogVisible)?_c('submit-dialog',{attrs:{"isSubmit":_vm.isSubmit,"settlementItemDetail":_vm.settlementItemDetail},on:{"query":_vm.query},model:{value:(_vm.submitDialogVisible),callback:function ($$v) {_vm.submitDialogVisible=$$v},expression:"submitDialogVisible"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }